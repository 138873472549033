// modal用のmoduleを記述（例）

.modalcol{
  width: 80%;
  min-width: 820px;
  display: none;
  @media screen and (max-device-width: 750px) {
    width: 100%;
    min-width: 100%;
  }
  svg{
    position: relative;
    top: 2px;
    width: 16px;
    height: 16px;
  }
}

.modal__listcol{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-device-width: 750px) {
    display: block;
  }
  .modal__listcol__col{
    width: 48.5%;
    margin: 0 3% 0 0;
    padding: 0;
    @media screen and (max-device-width: 750px) {
      width: 100%;
      margin: 0 0 24px 0;
    }
    text-align: center;
    &:last-child{
      margin: 0;
    }
    img{
      max-width: 90%;
      max-height: 220px;
      margin: 0 auto 14px;
      display: block;
    }
  }
}

.modal__listcol__col{
  width: 80%;
  margin: 0 auto;
  text-align: center;
  @media screen and (max-device-width: 750px) {
    width: 100%;
    margin: 0;
  }
  img{
    max-width: 80%;
    margin: 0 auto 14px;
    display: block;
    @media screen and (max-device-width: 750px) {
      max-width: 100%;
    }
  }
}

.fancybox-content{
  @media screen and (max-device-width: 750px) {
    padding: 24px !important;
  }
}