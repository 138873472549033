// コンテンツ部分の共通styleを記述（ページtitle、ブロック構成など）
#mainimgWrap{
	position: relative;
	width: 100%;
	margin: 0 0 54px 0;
	padding: 37% 0 0 0;
	color: $white;
	background: url(../img/main_bg.jpg) no-repeat 50% 50%;
	background-size: cover;
	@media screen and (max-width: 960px) {
		padding: 60% 0 0 0;
	}
	.mainimgWrap__inner{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mainimgWrap__col{
		width: 86%;
		margin: 0 auto;
		display: block;
		@media screen and (max-width: 960px) {
			width: 98%;
		}
		.mainimgWrap__col__img{
			width: 100%;
			margin: 0 auto;
			padding: 34.07% 0 0 0;
			background: url(../img/main.png) no-repeat 50% 50%;
			background-size: cover;
		}
		.mainimgWrap__h2{
			margin: -10px 0 0 0;
			padding: 0;
			color: $primary;
			font-family: $font-jp;
			text-align: center;
			font-size: 120%;
			font-weight: 700;
			@media screen and (max-width: 960px) {
				margin: 0;
				font-size: 80%;
			}
			.mainimgWrap__h2__em{
				margin: 4px 0 0 0;
				color: $white;
				display: block;
				font-style: normal;
				font-size: 160%;
				@media screen and (max-width: 960px) {
					font-size: 140%;
				}
			}
		}
	}
}

#workstopWrap{
	position: relative;
	margin: 0 0 54px 0;
	.workstopWrap__inner{
		position: relative;
		width: 900px;
		margin: 0 auto;
		@media screen and (max-width: 960px) {
			width: 100%;
		}
		.btnmore{
			top: -70px;
			right: 0;
			@media screen and (max-width: 960px) {
				top: auto;
				left: 0;
				right: 0;
				margin: auto;
			}
		}
	}
}

#contentsCol{
	position: relative;
	margin: 0 auto 50px;
	padding: 0 4%;
	max-width: 1300px;
	text-align: left;
	box-sizing: border-box;
	@include z-index(mainlayer);
	@media screen and (max-width:960px) {
			margin: 0 0 20px 0;
	}
	#top &{
			margin: 0 0 10px 0;
			padding: 0 4%;
	}
	.top__h2{
		margin: 0 0 18px 0 !important;
		padding: 0 !important;
		font-size: 140%;
		font-weight: 700 !important;
		text-align: center !important;
		display: block !important;
		border: none !important;
	}
}

.listbox{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
	flex-wrap: wrap;
	justify-content: center;
  @media screen and (max-width:960px) {
      width: 100%;
      margin: 0 auto;
      flex-wrap: block;
	}
	#top &{
		max-width: 1300px;
		padding: 0 4%;
		box-sizing: border-box;
	}
	#artists &{
		justify-content: flex-start;
		article{
			border-top: 4px solid $primary;
		}
	}
  article{
		position: relative;
		width: 23.5%;
		margin: 0 2% 34px 0;
		padding: 0;
		box-sizing: border-box;
		@media screen and (max-width:767px) {
				width: 48%;
				margin: 0 4% 18px 0;
		}
  }
  article:nth-child(4n){
		margin: 0 0 34px 0;
		@media screen and (max-width:767px) {
				margin: 0 4% 18px 0;
    }
  }
  .workscol{
		position: relative;
		padding: 23.5% 0 0 0 !important;
		background-position: 50% 50% !important;
		background-size: cover !important;
		@media screen and (max-width:767px) {
			padding: 48% 0 0 0 !important;
    }
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 96%;
			height: 96%;
			border: 1px solid $primary;
			box-sizing: border-box;
			opacity: 0.5;
			@include fadeinout;
		}
		&:hover{
			.workscol__info{
				background: $black;
			}
			&:after{
				border: 1px solid $secondary;
				opacity: 1;
			}
			.ftcol{
				border-top: 1px solid $secondary;
			}
		}
		.workscol__info{
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 0 2% 2%;
			color: $white;
			background: rgba(0,0,0,0.8);
			box-sizing: border-box;
			@include fadeinout;
		}
		.workscol__link{
			@include abase;
			@include z-index(mainlayer);
		}
  }
  article .imgcol{
		position: relative;
		padding: 64% 0 0 0;
		overflow: hidden;
		background: $bgcolor;
  }
  article .thm{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 64% 0 0 0;
		background-position: 50% 50% !important;
		background-size: cover !important;
		@include fadeinout;
  }
  article .articlett{
		@include fadeinout;
		&:hover{
		}
		.articlett__h3{
			height: 68px;
			margin: 0;
			text-align: left;
			font-size: 110%;
			line-height: 130%;
			font-weight: 500;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			padding: 8px;
			@media screen and (max-width:767px) {
				height: 52px;
				font-size: 100%;
			}
		}
  }
  article .articlett:hover .thm{
    transform: scale(1.2);
  }
  .article__link{
		@include abase;
		@include z-index(mainlayer);
  }
  article h3{
		height: 68px;
		margin: 0;
		padding: 0 4%;
		text-align: left;
		font-size: 110%;
		line-height: 130%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
  }
  article .ftcol{
		width: 100%;
		margin: 0 auto;
		padding: 0 3% 0 0;
		text-align: left;
		line-height: 130%;
		font-size: 74%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		border-top: 1px solid rgba(255,212,0,0.5);
		@include fadeinout;
		@media screen and (max-width:767px) {
			font-size: 60%;
		}
  }
  article .ftcol .artist{
    position: relative;
    width: 60%;
    margin: 0;
    padding: 0;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include fadeinout;
  }
  article .ftcol .artist:hover{
    opacity: 0.6;
  }
  article .ftcol .artist .icophoto{
    width: 18%;
    margin: 0 3% 0 0;
    padding: 18% 0 0 0;
    background-position: 50% 50% !important;
    background-size: cover !important;
  }
  article .ftcol .artist .name{
    width: 77%;
    margin: 0;
    padding: 0;
  }
  article .ftcol .date{
    width: 36%;
    margin: 0 0 0 4%;
    padding: 0;
    text-align: right;
  }
}

#artists{
	.listbox{
		justify-content: flex-start;
	}
  article{
		position: relative;
		width: 18%;
		margin: 0 2.5% 34px 0;
		padding: 0;
		box-sizing: border-box;
		border-top: 4px solid $primary;
		@media screen and (max-width:960px) {
				width: 30%;
				margin: 0 4.5% 24px 0;
		}
		@media screen and (max-width:767px) {
				width: 48%;
				margin: 0 4% 18px 0;
		}
  }
  article:nth-child(2n){
		@media screen and (max-width:767px) {
				margin: 0 0 18px 0;
		}
  }
  article:nth-child(3n){
		@media screen and (max-width:960px) {
				margin: 0 0 24px 0;
		}
		@media screen and (max-width:767px) {
				width: 48%;
				margin: 0 4% 18px 0;
		}
  }
  article:nth-child(5n){
		margin: 0 0 34px 0;
		@media screen and (max-width:767px) {
				margin: 0 4% 18px 0;
    }
  }
	.articlett__h3{
		height: auto;
		margin: 4px 0 0 0;
		text-align: left;
		font-size: 100%;
		@media screen and (max-width:767px) {
			height: 52px;
			font-size: 100%;
		}
	}
	.listbox article .imgcol{
    padding: 100% 0 0 0;
	}
	.listbox article .thm{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 100% 0 0 0;
		background-position: 50% 50% !important;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		@include fadeinout;
	}
}

.detailcol{
	max-width: 840px;
	margin: 0 auto 40px;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	@media screen and (max-width:960px) {
		max-width: 100%;
		display: block;
	}
	.col1{
		width: 30%;
		margin: 0 3% 0 0;
		box-sizing: border-box;
		@media screen and (max-width:960px) {
			width: 80%;
			margin: 0 auto 20px;
		}
		.detailcol__img{
			padding: 100% 0 0 0;
			background-size: cover !important;
			background-position: 50% 50% !important;
			border: 1px solid $primary;
			box-sizing: border-box;
			@media screen and (max-width:960px) {
				padding: 100% 0 0 0;
			}
		}
	}
	.col2{
		position: relative;
		width: 67%;
		margin: 0;
		padding: 14px 0 0 0;
		box-sizing: border-box;
		@media screen and (max-width:960px) {
			padding: 30px 0 0 0;
			width: 100%;
		}
	}
	.col2 h2{
		margin: 0 0 14px 0 !important;
		padding: 0 !important;
		font-size: 140% !important;
		font-weight: 500 !important;
		line-height: 130%;
		border: 0 !important;
		@media screen and (max-width:960px) {
			margin: 0 0 10px 0 !important;
			font-size: 130% !important;
		}
	}
	.col2 .infocol{
		margin: 0 0 24px 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		@media screen and (max-width:960px) {
		}
	}
	.col2 .infocol .artist{
		position: relative;
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include fadeinout;
		cursor: pointer;
		cursor: hand;
		@media screen and (min-width:768px) and (max-width:960px) {
			width: 40%;
		}
		@media screen and (max-width:767px) {
			width: 50%;
		}
	}
	.col2 .infocol .artist:hover{
		opacity: 0.6;
	}
	.col2 .infocol .artist a{
		@include abase;
	}
	.col2 .infocol .artist .icophoto{
		width: 4%;
		margin: 0 2% 0 0;
		padding: 4% 0 0 0;
		background-position: 50% 50% !important;
		background-size: cover !important;
		@media screen and (max-width:767px) {
			width: 14%;
			margin: 0 4% 0 0;
			padding: 14% 0 0 0;
		}
	}
	.col2 .infocol .name{
		width: 90%;
		margin: 0;
		padding: 0;
		@media screen and (max-width:767px) {
			width: 82%;
		}
	}
	.col2 .infocol .sharecol{
		padding-top: 3px;
		@media screen and (max-width:960px) {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
		}
	}
	.col2 h3{
		font-size: 110% !important;
		font-weight: 500 !important;
		padding: 4px 0 4px 10px;
		box-sizing: border-box;
		border-left: 3px solid $secondary !important;
		@media screen and (max-width:960px) {
			font-size: 100% !important;
			padding: 2px 0 2px 8px;
		}
	}
	.col2 .detaillist dl{
		margin: 0 0 34px 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		@media screen and (max-width:960px) {
			display: block;
		}
	}
	.col2 .detaillist dt{
		width: 24%;
		margin: 0 0 10px 0;
		padding: 0 4% 10px 0;
		border-bottom: 1px solid $gray7;
		box-sizing: border-box;
		@media screen and (max-width:960px) {
			width: 100%;
			margin: 0;
			padding: 0;
			font-size: 86%;
			font-weight: 600;
			border-bottom: 0;
		}
	}
	.col2 .detaillist dd{
		width: 76%;
		margin: 0 0 10px 0;
		padding: 0 0 10px 0;
		border-bottom: 1px solid $gray7;
		box-sizing: border-box;
		@media screen and (max-width:960px) {
			width: 100%;
		}
	}
}

.procol{
	max-width: 840px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start !important;
	margin: 0 auto 40px;
	padding: 0 0 30px 0;
	border-bottom: 1px solid $gray2;
	@media screen and (max-width:960px) {
			display: block;
	}
	.photo{
		width: 30%;
		margin: 0;
		padding: 30% 0 0 0;
		background-position: 50% 50% !important;
		background-size: contain !important;
		border: 1px solid $primary;
		@media screen and (max-width:960px) {
				width: 60%;
				margin: 0 auto 14px;
				padding: 60% 0 0 0;
		}
	}
	.txt{
		width: 67%;
		margin: 0;
		padding: 0;
		@media screen and (max-width:960px) {
				width: 100%;
				margin: 0;
				padding: 0;
		}
	}
	.txt h2{
		margin: 0 0 12px 0 !important;
		padding: 0 !important;
		font-size: 140% !important;
		border: 0 !important;
		@media screen and (max-width:960px) {
				font-size: 130% !important;
				text-align: center;
		}
	}
}

.snsCol{
	position: relative;
	width: 100%;
	margin: 20px 0 0 0;
	@media screen and (max-width:960px) {
	}
	footer & ul{
		justify-content: center;
		li{
			margin: 0 10px;
		}
	}
	ul{
		width: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		list-style-type: none;
	}
	li{
		position: relative;
		width: 40px;
		height: 40px;
		margin: 0;
		text-align: left;
		box-sizing: border-box;
	}
	li a{
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		padding: 0;
		text-decoration: none !important;
		font-style: normal;
		@include fadeinout;
	}
	li a:after{
	content: "";
	margin: 0;
	width: 40px;
	height: 40px;
	font-size: 180%;
	line-height: 100%;
	font-style: normal;
	font-family: 'Font Awesome 5 Brands';
	text-align: center;
	vertical-align: bottom;
	box-sizing: border-box;
	transition: all 0.3s ease-out;
	}
	li a:hover{
		opacity:0.6;
	}
	li.website a:after{
		content:"\f015";
		font-family: 'FontAwesome';
		font-size: 200%;
		padding: 10px 0 0 0;
		color: $black;
	}
	li.tw a:after{
		content:"\f099";
		padding: 14px 0 0 0;
		color: #55ACEE;
	}
	li.insta a:after{
		content:"\f16d";
		padding: 14px 0 0 0;
		color: #D93177;
	}
	li.fb a:after{
		content:"\f09a";
		padding: 11px 0 0 0;
		color: #3B5998;
	}
}

.sharecol{
	position: absolute;
	top: -18px;
	right: 0;
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: left;
	font-size: 90%;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	#detail &{
		margin: 0 0 10px 0;
		padding: 0;
		justify-content: flex-end;
		@media screen and (max-width:960px) {
				margin: 0 0 6px 0;
		}
	}
	&::before{
			position: relative;
			content: 'SHARE：';
			text-align: left;
			margin: 4px 0 0 0;
	}
	li{
		position: relative;
		margin: 0;
		padding: 0;
		line-height: 100%;
		box-sizing: border-box;
		font-weight: 500;
		@include fadeinout;
	}
	li:last-child{
		border-bottom: 0;
	}
	li span{
		font-size: 50%;
	}
	li:hover{
		opacity: 0.6;
	}
	li a{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		cursor: pointer;
		cursor: hand;
		box-sizing: border-box;
	}
	li::before{
		position: relative;
		content: '';
		top: 1px;
		margin: 0 6px;
		padding: 0;
		line-height: 100%;
		font-family: 'Font Awesome 5 Brands';
		font-size: 160%;
		color: $gray2;
		@include fadeinout;
		@media screen and (max-width:960px) {
			margin: 0 6px;
			font-size: 140%;
		}
	}
	li.sharecopy::before{
		content: 'URLをコピー';
		top: 4px;
		padding: 4px 6px;
		font-size: 90%;
		line-height: 100%;
		border-radius: 4px;
		color: $gray7;
		background: $white;
		border: 1px solid $gray7;
		box-sizing: border-box;
		@media screen and (max-width:960px) {
				font-size: 80%;
		}
	}
	li.sharecopy.done::before{
			content: 'コピー完了';
	}
	li.sharetw::before{
		content: '\f099';
		color: #55acee;
	}
	li.sharefb::before{
		content: '\f09a';
		color: #3B5998;
	}
	li.shareline::before{
		content: '\f3c0';
		color: #00b900;
		@media screen and (max-width:960px) {
				display: inline-block;
		}
	}
	li.sharemail::before{
		font-family: 'FontAwesome';
		content: '\f003';
		color: $gray;
	}
}

#newsWrap{
	position: relative;
	margin: 0 0 54px 0;
	.newsWrap__inner{
		position: relative;
		width: 900px;
		margin: 0 auto;
		@media screen and (max-width: 960px) {
			width: 100%;
		}
		.btnmore{
			top: -70px;
			right: 0;
			@media screen and (max-width: 960px) {
				top: auto;
				left: 0;
				right: 0;
				margin: auto;
			}
		}
	}
	.newsWrap__listcol{
		position: relative;
		width: 100%;
		margin: 0;
		padding: 20px 0;
		border-bottom: 1px solid $gray;
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		@include fadeinout;
		@media screen and (max-width: 960px) {
			margin: 0 0 20px;
			padding: 20px 4%;
			display: block;
		}
		&:first-child{
			border-top: 1px solid $gray;
		}
		&::after{
			position: absolute;
			content: '\f054';
			top: 50%;
			right: 0;
			margin: -7px 0 0 0;
			color: $gray;
			line-height: 100%;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			font-size: 90%;
			text-align: center;
			@media screen and (max-width: 960px) {
				right: 3%;
			}
		}
		&:hover{
			background: $primary;
		}
	}
	.newsWrap__listcol__link{
		@include abase;
	}
	.newsWrap__listcol__date{
		width: 20%;
		margin: 0 4% 0 0;
		@media screen and (max-width: 960px) {
			width: 100%;
			margin: 0 0 4px 0;
		}
	}
	.newsWrap__listcol__txt{
		width: 76%;
		margin: 0;
		@media screen and (max-width: 960px) {
			width: 94%;
			margin: 0;
		}
	}
	.newsWrap__col{
		position: relative;
		margin: 0 0 40px 0;
		@media screen and (max-width: 960px) {
			width: 94%;
			margin: 0 auto 40px;
		}
		img{
			max-width: 100%;
			height: auto;
			margin: 0 0 24px 0;
			display: block;
		}
		iframe{
			max-width: 100%;
			height: 300px;
			margin: 0 0 24px 0;
			display: block;
		}
	}
	.newsWrap__h3{
		margin: 0 0 24px 0;
		padding: 0 0 14px 0;
		border-bottom: 1px solid $gray;
		box-sizing: border-box;
		.newsWrap__h3__em{
			margin: 0 0 8px 0;
			font-size: 80%;
			font-weight: 400;
			font-style: normal;
			display: block;
		}
	}
}

#overviewWrap{
	position: relative;
	padding: 0;
	.overviewWrap__inner{
		margin: -59px 0 0 0;
		padding: 56px 0;
		background: $light;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		@media screen and (max-width: 960px) {
			margin: -39px 0 0 0;
			padding: 19px 0 0 0;
			display: block;
		}
	}
	.overviewWrap__img1{
		width: 22%;
		margin: 0 3% 0 0;
		background: url(../img/photo01.jpg) no-repeat 50% 50%;
		background-size: cover;
		@media screen and (max-width: 960px) {
			width: 100%;
			margin: 0 0 24px 0;
			padding: 30% 0 0 0;
		}
	}
	.overviewWrap__img2{
		width: 22%;
		margin: 0;
		background: url(../img/photo02.jpg) no-repeat 50% 50%;
		background-size: cover;
		@media screen and (max-width: 960px) {
			width: 100%;
			padding: 30% 0 0 0;
		}
	}
	.overviewWrap__txt{
		width: 50%;
		margin: 0 3% 0 0;
		text-align: center;
		@media screen and (max-width: 960px) {
			width: 92%;
			margin: 0 auto 24px;
			text-align: left;
		}
		p{
			line-height: 190%;
			@media screen and (max-width: 960px) {
				font-size: 86%;
				line-height: 170%;
			}
		}
	}
}

#parentsWrap{
	margin: 0 0 54px 0;
	.parentsWrap__col{
		position: relative;
		margin: 0 0 50px 0;
		@media screen and (max-width: 960px) {
			width: 94%;
			margin: 0 auto 40px;
		}
	}
	.parentsWrap__h3{
		margin: 0 0 24px 0;
		padding: 0 0 14px 0;
		border-bottom: 1px solid $gray;
		box-sizing: border-box;
	}
}

#aboutWrap{
	margin: 0 0 54px 0;
	position: relative;
	padding: 0;
	@media screen and (max-width: 960px) {
	}
	.aboutWrap__col{
		width: 900px;
		margin: 0 auto 50px;
		padding: 0 0 22px 0;
		text-align: left;
		color: $black;
		@media screen and (max-width: 960px) {
			width: 92%;
		}
		&:last-child{
			margin: 0 auto;
		}
		img{
			max-width: 100%;
			height: auto;
			margin: 0 0 24px 0;
			display: block;
		}
		iframe{
			max-width: 100%;
			min-width: 80%;
			height: 320px;
			margin: 0 auto 24px;
			display: block;
		}
	}
	.aboutWrap__h3{
		margin: 0 0 24px 0;
		padding: 0 0 14px 0;
		border-bottom: 1px solid $gray;
		box-sizing: border-box;
	}
}

#contactWrap{
	width: 70%;
	max-width: 900px;
	margin: 0 auto 54px;
	position: relative;
	padding: 0;
	@media screen and (max-width: 960px) {
		width: 92%;
		max-width: 92%;
	}
	dl{
		width: 100%;
		margin: 0 auto;
		padding: 0;
		font-size: 110%;
		display: flex;
		flex-wrap: wrap;
	}
	dl dt{
		width: 24%;
		margin: 0 3% 0 0;
		padding: 0;
		line-height: 130%;
		text-align: right;
		@media screen and (max-width:960px) {
				width: 100%;
			text-align: left;
		}
	}
	dl dt .req{
		margin: 0;
		padding: 0;
		font-size: 80%;
		color: $red;
		display: block;
		@media screen and (max-width:960px) {
			margin: 0 0 6px 0;
		}
	}
	dl dd{
		width: 73%;
		margin: 0 0 24px 0;
		padding: 0;
		text-align: left;
		@media screen and (max-width:960px) {
				width: 100%;
				font-size: 80%;
		}
	}
	dl dd input[type="text"],dl dd input[type="email"],dl dd textarea{
		width: 100%;
		font-size: 16pt;
		margin: 0;
		padding: 6px;
		outline: none;
		resize: none;
		appearance: none;
		text-align: left;
		box-sizing: border-box;
		border: 1px solid $gray;
	}
	input[type="submit"]{
		margin: 0 auto;
		padding: 14px 12%;
		text-align: center;
		outline: none;
		appearance: none;
		border: 0;
		font-size: 120%;
		border-radius: 100px;
		box-sizing: border-box;
		background: $primary;
		@include fadeinout;
		@media screen and (max-width:960px) {
				width: 100%;
				margin: 14px 0 0 0;
		}
	}
	input[type="submit"]:hover{
		color: $white;
		background: $secondary;
	}
	dl dd select{
		position: relative;
		width: 100%;
		font-size: 16pt;
		margin: 0;
		padding: 6px;
		outline: none;
		resize: none;
		appearance: none;
		text-align: left;
		box-sizing: border-box;
		background: transparent !important;
		border: 1px solid $gray;
	}
	dl dd.selectform{
		position: relative;
	}
	dl dd.selectform::after{
		position: absolute;
		content:'\f107';
		top: 20%;
		right: 16px;
		line-height: 100%;
		font-family: 'FontAwesome';
		font-size: 150%;
		text-align: right;
		color: $gray2;
		@media screen and (max-width:960px) {
		}
	}
	.mw_wp_form_confirm .selectform::after{
		display: none;
	}
	input.formsend{
		width: 50%;
		margin: 24px auto;
		background: rgba(255,255,255,0.1) !important;
		border: none;
		@media screen and (max-width:960px) {
				width: 100%;
		}
	}
}