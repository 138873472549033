// く使用するstyleを変数化し、ここに集約
$white: #fff;
$black: #222;
$darkblue: #314048;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #FF2E5B;
$red: #cc0000;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #7BCABD;
$teal: #20c997;
$cyan: #BAF0E4;
$white: #fff;
$gray: #ccc;
$gray2: #ececec;
$gray3: #ccc;
$gray4: #B4B4B4;
$gray5: #F7F7F7;
$gray6: #adadad;
$gray7: #999;
$gray-dark: #343a40;
$primary: #FFD400;
$secondary: #45B1FF;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #cc0000;
$light: #FFFDF2;
$dark: #314048;
$bgcolor: #FAF8EF;
$facebook: #3C5A99;
$instagram: #CF2E92;
$line: #00B900;
$txt: #222;
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

//setting z-index
$layer:(
  blackbg: 50,
  openbn: 120,
  closebtn: 820,
  navlink: 200,
  topnav: 100,
  snsnav: 96,
  header: 600,
  navilayer: 800,
  topbtn: 700,
  articlelink: 20,
  artcilebg: 12,
  mainlayer: 10,
  mainbg: -1,
  default: 1
);

//font
$font-normal: 'M PLUS 1p', sans-serif;
$font-jp: 'M PLUS Rounded 1c', sans-serif;