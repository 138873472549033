body {
	position: relative;
	padding: 0;
  color: $black;
  word-wrap: break-word;
  display: block;
  font: 16px/1.5em, 'M PLUS 1p', sans-serif;
	font-family: 'M PLUS 1p', sans-serif;
	box-sizing: border-box;
}

header{
  position: fixed;
	width: 100%;
	margin: 0;
  padding: 0;
  height: 170px;
	background: $white;
  box-sizing: border-box;
  @include z-index(header);
  @media screen and (max-width: 960px) {
		height: 117px;
	}
	.headerWrap{
		position: relative;
		height: 134px;
		margin: 0;
		padding: 0;
		background: url(../img/header.png) repeat-x 50% 0;
		background-size: cover;
		@media screen and (max-width: 960px) {
			height: 91px;
			background: url(../img/header_sp.png) repeat-x 50% 0;
			background-size: cover;
		}
	}
	.headerWrap__h1{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 7px;
		width: 174px;
		margin: 0 auto;
		font-size: 90%;
		font-weight: 500;
		text-align: center;
		display: block;
		text-shadow: 2px  2px 1px $white,
		-2px  2px 1px $white,
		 2px -2px 1px $white,
		-2px -2px 1px $white,
		 2px  0px 1px $white,
		 0px  2px 1px $white,
		-2px  0px 1px $white,
		 0px -2px 1px $white;
		 @media screen and (max-width: 960px) {
			width: 144px;
			font-size: 75%;
		 }
		.header__logo{
			width: 110px;
			height: auto;
			margin: 0 auto 4px;
			display: block;
			@media screen and (max-width: 960px) {
				width: 70px;
			}
		}
		.headerWrap__h1__link{
			@include abase;
		}
	}
	.btnopen{
		position: absolute;
		top: 16px;
		left: 50%;
		margin: 0 0 0 -200px;
		padding: 8px 0 0 20px;
		width: 100px;
		height: 108px;
		font-weight: 700;
		color: $white;
		cursor: pointer;
		background: url(../img/nav.svg) no-repeat 50% 50%;
		background-size: cover;
		box-sizing: border-box;
		@include z-index(topbtn);
		@include fadeinout;
		@media screen and (max-width: 960px) {
			position: fixed;
			top: 13px;
			left: 11px;
			width: 66px;
			height: 70px;
			margin: 0;
			padding: 4px 0 0 10px;
			font-size: 74%;
		}
		&:hover{
			opacity: 0.6;
		}
	}
	.header__btndl{
		position: absolute;
		bottom: 14px;
		right: 50%;
		width: 140px;
		height: 34px;
		margin: 0 -260px 0 0;
		color: $white;
		font-size: 100%;
		font-weight: 700;
		text-decoration: none;
		background: $black;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include fadeinout;
		@media screen and (max-width: 960px) {
			position: fixed;
			top: 42px;
			right: 0;
			bottom: auto;
			width: 86px;
			height: 40px;
			margin: 0;
			padding: 0;
			font-size: 74%;
			border-radius: 100px 0 0 100px;
		}
		&:hover{
			background: $primary;
		}
	}
	.header__line{
		height: 36px;
		background: $primary url(../img/star.svg) repeat-x 50% 50%;
		@media screen and (max-width: 960px) {
			height: 26px;
			background-size: 7%;
		}
	}
}

.btnclose{
  position: fixed;
  top: 22px;
  right: 10px;
  width: 36px;
  height: 24px;
  margin: 0 auto;
  display: block;
  visibility:hidden;
  opacity: 0;
  cursor: pointer;
  cursor: hand;
  @include z-index(closebtn);
  @include fadeinout;
	@media screen and (max-width: 960px) {
		top: 18px;
		right: 0;
	}
  &:before{
      @include closebtn;
      transform: rotate(45deg);
  }
  &:after{
      @include closebtn;
      transform: rotate(-45deg);
  }
  &:hover{
      opacity: 0.6;
  }
  &.active{
      opacity: 1;
      visibility:visible;
  }
}

.btnmore{
	position: absolute;
	top: 0;
	right: 0;
	padding: 8px 10px;
	font-weight: 500;
	color: $white;
	font-size: 86%;
	line-height: 100%;
	text-decoration: none;
	background: $secondary;
	border-radius: 100px;
	box-sizing: border-box;
	display: inline-block;
	@include fadeinout;
	@media screen and (max-width: 960px) {
		position: relative;
		padding: 12px 10px;
		width: 80%;
		margin: 0 auto;
		text-align: center;
		display: block;
	}
	&:hover{
		background: $black;
	}
	i{
		margin: 0 3px 0 0;
	}
}

.btnmore2{
	position: relative;
	padding: 12px 20px;
	font-weight: 500;
	color: $white;
	font-size: 86%;
	line-height: 100%;
	text-decoration: none;
	background: $secondary;
	border-radius: 100px;
	box-sizing: border-box;
	display: inline-block;
	@include fadeinout;
	@media screen and (max-width: 960px) {
		position: relative;
		padding: 12px 10px;
		width: 80%;
		margin: 0 auto;
		text-align: center;
		display: block;
	}
	&:hover{
		background: $black;
	}
	i{
		margin: 0 3px 0 0;
	}
}

/*---navi---*/
#navi{
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
	justify-content: center;
	@include fadeinout;
  &.active{
    visibility: visible;
    opacity: 1;
    @include z-index(navilayer);
    @include fadeinout;
    background: rgba(69,177,255,0.95) url(../img/navi_img01.png) no-repeat 15px 0;
		background-size: 378px;
    @media screen and (max-device-width:750px) {
			background-size: 178px;
    }
		&::after{
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			width: 210px;
			height: 167px;
			background: url(../img/navi_img02.png) no-repeat right bottom;
			background-size: 100%;
			@media screen and (max-device-width:750px) {
				width: 100px;
				height: 80px;
			}
		}
  }
}

#navilist{
  width: 492px;
  margin: 0;
  padding: 0;
  text-align: center;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
  @include z-index(navilink);
  @media screen and (max-device-width:750px) {
    width: 272px;
    margin: 0 auto;
    padding: 0;
	}
  .navilist__li{
		position: relative;
		width: 140px;
		height: 140px;
    margin: 12px;
    padding: 0;
    color: $white;
    font-size: 100%;
    font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid $white;
		border-radius: 100%;
		box-sizing: border-box;
    @include fadeinout;
    @media screen and (max-device-width:750px) {
			margin: 8px;
			width: 120px;
			height: 120px;
      padding: 20px 0;
	    font-size: 100%;
    }
	  &:hover{
			background: $primary;
	    @media screen and (max-device-width:750px) {
		    opacity: 1;
	    }
    }
	  a{
	    @include abase;
		}
	}
}

main{
	position: relative;
  margin: 0;
  padding: 210px 0 0 0;
	@media screen and (max-width: 960px) {
		padding: 144px 0 0 0;
	}
	#top &{
		padding: 170px 0 0 0;
		@media screen and (max-width: 960px) {
			padding: 117px 0 0 0;
		}
	}
}

.mainContent__h2{
	position: relative;
	margin: 0 0 34px 0;
	padding: 13px 40px;
	font-size: 140%;
	font-weight: 500;
	background: $primary url(../img/bg_index.png) repeat 50% 50%;
	display: inline-block;
	@media screen and (max-width: 960px) {
		margin: 0 0 20px 0;
		padding: 8px 24px;
		font-size: 110%;
	}
	&::before{
		position: absolute;
		content: '';
		width: 0px;
		height: 0px;
		z-index: 1;
	}
	&::after {
		position: absolute;
		content: '';
		width: 0px;
		height: 0px;
		z-index: 1;
	}
	&::before{
		content: '';
		top: 0;
		left: 0;
		border-width: 25px 0px 25px 25px;
		border-color: transparent transparent transparent $white;
		border-style: solid;
		@media screen and (max-width: 960px) {
			border-width: 18px 0px 18px 14px;
		}
	}
	&::after {
		content: '';
		top: 0;
		right: 0;
		border-width: 25px 25px 25px 0px;
		border-color: transparent #fff transparent transparent;
		border-style: solid;
		@media screen and (max-width: 960px) {
			border-width: 18px 14px 18px 0;
		}
	}
}

footer{
  position: relative;
  padding: 40px 0;
	color: $white;
	text-align: center;
	background: $black;
	.footer__logo{
		width: 100px;
		height: auto;
		margin: 0 auto 10px;
		display: block;
	}
  small{
    font-size: 70%;
		@media screen and (max-width: 960px) {
	  	font-size: 70%;
		}
  }
}

.topBtn{
	position: fixed;
	bottom: 24px;
	right: 14px;
	color: $white;
	text-decoration: none;
	width: 77px;
	height: 64px;
	text-align: center;
	box-sizing: border-box;
	background: url(../img/pagetop.svg) no-repeat 50% 50%;
	background-size: cover;
	@include z-index(topbtn);
	@media screen and (max-width: 960px) {
		right: 10px;
		width: 60px;
		height: 50px;
	}
	&.active{
		bottom: 76px;
	}
	&::before{
		position:absolute;
		content:'\f062';
		bottom: 10px;
		left: -20px;
		right: 0;
		margin: auto;
		width: 100%;
		line-height: 100%;
		font-family: "Font Awesome 5 Free";
		font-size: 140%;
		font-weight: 900;
		text-align: center;
		@include fadeinout;
		@media screen and (max-width: 960px) {
			bottom: 8px;
			left: -16px;
			font-size: 110%;
		}
	}
	&:hover::before{
		color: $black;
	}
	a{
		@include abase;
	}
}

.newmark{
	position: absolute;
	top: 0;
	left: 0;
	width: 60px;
	height: 24px;
	padding: 0 0 0 10px;
	line-height: 110%;
	font-size: 80%;
	font-weight: 800;
	box-sizing: border-box;
	color: $white;
	background: $pink;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	@include z-index(mainlayer);
	@media screen and (max-width:767px) {
		width: 44px;
		height: 44px;
		font-size: 50%;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 0px;
		height: 0px;
		z-index: 1;
		border-width: 12px 12px 12px 0px;
		border-color: transparent #fff transparent transparent;
		border-style: solid;
	}
}

.wp-pagenavi{
	margin: 30px 0;
	text-align:center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	a{
		height: 34px;
		text-align :center;
		text-decoration: none;
		padding: 9px 12px 0 !important;
		margin: 0 10px;
		color: $black !important;
		background: $gray2;
		text-decoration:none;
		line-height:100%;
		display: inline-block;
		box-sizing :border-box !important;
		border-radius: 100px;
		border: none !important;
		@include fadeinout;
	}
	a:hover{
		color:#fff !important;
		background: $secondary;
		text-decoration:none;
		opacity:1;
	}
	span{
		height: 34px;
		text-align:center;
		text-decoration: none;
		padding: 9px 12px 0 !important;
		margin: 0 10px;
		line-height: 100%;
		color: $white;
		display:inline-block;
		box-sizing:border-box !important;
		background: $secondary;
		border-radius: 100px;
		border: none !important;
	}
}

p{
  line-height: 150%;
  margin: 0 0 16px 0;
}

.innerCol{
	width: 900px;
	margin: 0 auto;
	@media screen and (max-width: 960px) {
		width: 100%;
	}
}

.text-center{
	text-align: center;
}

.pc{
  display: block;
  @media screen and (max-width: 960px) {
     display: none;
  }
}

.sp{
  display: none;
  @media screen and (max-width: 960px) {
     display: block;
  }
}

a{
	outline: none;
}